import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import AppTable from '../../../components/AppTable';
import { IAppTableRow } from '../../../components/AppTable/types/table.types';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';
import ReportsNoRecords from '../components/NoRecords';
import { SHIFT_SUMMARY_COLUMNS } from '../constants';
import {
  IShiftSummaryResponseDTO,
  IShiftSummaryTable,
} from '../types/shift-summary.types';
import { getFormattedShiftSummary } from '../utils/formatting.utils';

type Props = {
  total: number;
  pageIndex: number;
  pageSize: number;
  onChange: (pageIndex: number) => void;
};

const ShiftsSummaryTableView = ({
  total,
  pageIndex,
  pageSize,
  onChange,
}: Props) => {
  const { shiftsReportStatus, ShiftSummaryData, extras, refreshing } =
    useAppSelector(selectShiftSummary);
  const navigate = useNavigate();
  const { restaurant } = useAppSelector(selectAuth);

  const formattedShiftSummaries = useMemo(() => {
    return ShiftSummaryData.map((shiftSummary: IShiftSummaryResponseDTO) => {
      return getFormattedShiftSummary(
        shiftSummary,
        String(restaurant?.posCurrencySymbol),
      );
    });
  }, [ShiftSummaryData, restaurant?.posCurrencySymbol]);

  const handlePreviousClick = () => onChange(pageIndex - 1);
  const handleNextClick = () => onChange(pageIndex + 1);

  const handleOnClickRow = async (data: IAppTableRow<IShiftSummaryTable>) => {
    navigate(`/reports/shifts-summary/detailed/${data.key}`);
  };

  return (
    <>
      {shiftsReportStatus.showLoadingScreen ? (
        <AppTable columns={SHIFT_SUMMARY_COLUMNS} rows={[]} preview />
      ) : (
        <AppTable
          refreshing={refreshing}
          columns={SHIFT_SUMMARY_COLUMNS}
          rows={formattedShiftSummaries}
          onClickRow={handleOnClickRow}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
          lastUpdatedOn={extras.lastUpdated}
          pagination={{
            total: total,
            pageIndex: pageIndex,
            pageSize: pageSize,
          }}
          emptyComponent={<ReportsNoRecords />}
        />
      )}
    </>
  );
};

export default ShiftsSummaryTableView;
