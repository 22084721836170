import { useAppSelector } from '../../../hooks';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';
import SummaryCard from '../components/SummaryCard';

const ShiftsSummaryHeaderView = () => {
  const { shiftsReportStatus } = useAppSelector(selectShiftSummary);

  if (shiftsReportStatus.showLoadingScreen) {
    return (
      <SummaryCard
        link={'https://eatme.sg/cloud-pos/'}
        title={'Getting Started With Summary'}
        body=" The report serves as a crucial tool for matching and verifying
payments received against transactions, playing a pivotal role in
ensuring meticulous financial reconciliation. By providing a
systematic overview, it contributes to the accuracy of the
reconciliation process, enabling organizations to maintain financial
integrity and make well-informed decisions."
      />
    );
  }

  return <></>;
};

export default ShiftsSummaryHeaderView;
