import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';

import AppExportButton from '../../../components/AppExportButton';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';
import { PaginatedResponseDTO } from '../../../types/api.types';
import { EXPORT_SHIFT_SUMMARY_COLUMNS } from '../constants';
import { fetchShiftSummaryDataExport } from '../redux/shiftSummarySlice';
import {
  IShiftSummaryResponseDTO,
  IShiftSummaryTable,
} from '../types/shift-summary.types';
import { getFormattedShiftSummary } from '../utils/formatting.utils';

const ShiftsSummaryExport = () => {
  const dispatch = useAppDispatch();

  const { reportStartDate, reportEndDate } = useAppSelector((state) => ({
    reportStartDate: dayjs(selectApp(state).reportStartDate)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    reportEndDate: dayjs(selectApp(state).reportEndDate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
  }));

  const { shiftsReportStatus } = useAppSelector(selectShiftSummary);

  const debouncedFetchExportData = useMemo(
    () =>
      debounce(
        async () => {
          const response = await dispatch(
            fetchShiftSummaryDataExport({
              date_from: reportStartDate,
              date_to: reportEndDate,
            }),
          );

          let results: IShiftSummaryResponseDTO[] = [];

          if (response.meta.requestStatus === 'fulfilled' && response.payload) {
            const payload = response.payload as PaginatedResponseDTO<
              IShiftSummaryResponseDTO,
              never
            >;

            results = payload.results;
          }
          return results;
        },
        500,
        { leading: true, trailing: false },
      ),
    [dispatch, reportEndDate, reportStartDate],
  );

  return (
    <AppExportButton<IShiftSummaryResponseDTO, IShiftSummaryTable>
      fileName={`detailedshiftsummary`}
      columns={EXPORT_SHIFT_SUMMARY_COLUMNS}
      dataFetcher={debouncedFetchExportData}
      formatter={getFormattedShiftSummary}
      disabled={shiftsReportStatus.showLoadingScreen}
      hideColumnSelector={true}
      buttonSx={{ height: 30, fontSize: '12px' }}
      iconSx={{ width: 14 }}
    />
  );
};

export default ShiftsSummaryExport;
