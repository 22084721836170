import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import AppDateRangePicker from '../../../components/AppDateRangePicker';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { usePaginationState } from '../../../hooks/usePaginationState';
import { setReportDates } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';
import { Colors } from '../../../theme/colors';
import ShiftsSummaryExport from '../components/ShiftsSummaryExport';

const ShiftsSummaryFilterView = () => {
  const dispatch = useAppDispatch();

  const { shiftsReportStatus, extras, refreshing } =
    useAppSelector(selectShiftSummary);

  const { reportStartDate, reportEndDate } = useAppSelector((state) => ({
    reportStartDate: dayjs(selectApp(state).reportStartDate)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    reportEndDate: dayjs(selectApp(state).reportEndDate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
  }));

  const { onChange, pageSize } = usePaginationState({
    total: extras.total,
    pageSize: extras.limit ?? 10,
    pageIndex:
      (extras.skip ?? 0) > 0
        ? Math.floor((extras.skip ?? 0) / (extras.limit ?? 0))
        : 0,
  });

  const onCalendarSave = (startDate: string | null, endDate: string | null) => {
    onChange(0, pageSize);
    dispatch(
      setReportDates({
        reportStartDate: startDate ?? undefined,
        reportEndDate: endDate ?? undefined,
      }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        mb: 2,
        border: `1px solid ${Colors.backgroundShade}`,
        backgroundColor: Colors.backgroundSecondary,
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 1.2 }}>
        <AppDateRangePicker
          initialValues={[dayjs.tz(reportStartDate), dayjs.tz(reportEndDate)]}
          disabled={shiftsReportStatus.showLoadingScreen || refreshing}
          onSave={onCalendarSave}
        />
        <Box sx={{ ml: 1.2 }}>
          <ShiftsSummaryExport />
        </Box>
      </Box>
    </Box>
  );
};

export default ShiftsSummaryFilterView;
