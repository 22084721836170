import ClearAllIcon from '@mui/icons-material/ClearAll';

import AppEmptyTable from '../../../components/AppEmptyTable';
import { Colors } from '../../../theme/colors';

const ReportsNoRecords = () => {
  return (
    <AppEmptyTable
      title="No Records Available"
      icon={<ClearAllIcon sx={{ color: Colors.primary }} />}
    />
  );
};

export default ReportsNoRecords;
