import NorthEastIcon from '@mui/icons-material/NorthEast';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AppButton from '../../../components/AppButton';
import { Colors } from '../../../theme/colors';

interface SummaryCardProps {
  link: string;
  title: string;
  body: string;
}

const SummaryCard = ({ link, title, body }: SummaryCardProps) => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        my: 2,
        backgroundColor: Colors.primaryLight,
        border: `1px solid ${Colors.primary}`,
      }}>
      <Box p={2}>
        <Typography variant="h6" sx={{ color: Colors.primary, mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: Colors.black }}>
          {body}
        </Typography>
        <AppButton
          onClick={() => {
            window.open(link, '_blank');
          }}
          sx={{
            border: `1px solid ${Colors.primary}`,
            backgroundColor: Colors.white,
            mt: 2,
            fontSize: '14px',
          }}>
          {' '}
          Learn More <NorthEastIcon sx={{ ml: 1, fontSize: '15px' }} />
        </AppButton>
      </Box>
    </Box>
  );
};

export default SummaryCard;
