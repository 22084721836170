import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { Colors } from '../../theme/colors';
import {
  formatDateTime,
  RangePreset,
  rangePresets,
} from '../AppDateTimeRangePicker/utils';
import AppSelector from '../AppSelector';
import { SNACKBARTYPE, useSnackbar } from '../AppSnackbar';
import { SelectChangeEvent } from '@mui/material';

const GRANULARITY = 'day';

type Props = {
  initialValues?: [Dayjs | null, Dayjs | null];
  disabled?: boolean;
  onSave?: (startDate: string | null, endDate: string | null) => void;
  disablePresets?: boolean;
};

const AppDateRangePicker = ({
  initialValues: defaultValues,
  disabled,
  onSave,
  disablePresets,
}: Props) => {
  const [value, setValue] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [selectedPreset, setSelectedPreset] = useState<
    RangePreset['id'] | 'custom'
  >('today');
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (defaultValues?.[0] && defaultValues?.[1]) {
      if (defaultValues[0].isAfter(defaultValues[1], GRANULARITY)) {
        setSelectedPreset('custom');
        setValue([null, null]);
      } else {
        setValue([
          defaultValues[0].startOf(GRANULARITY),
          defaultValues[1].endOf(GRANULARITY),
        ]);
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    const preset = rangePresets.find(
      (p) =>
        p.value[0]?.isSame(value[0], GRANULARITY) &&
        p.value[1]?.isSame(value[1], GRANULARITY),
    );

    setSelectedPreset(preset ? preset.id : 'custom');
  }, [value]);

  const handleOnPresetChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const preset = rangePresets.find((p) => p.id === e.target.value);
      if (preset) {
        setSelectedPreset(preset.id);
        setValue([preset.value[0], preset.value[1]]);
        onSave?.(
          formatDateTime(preset.value[0]),
          formatDateTime(preset.value[1]),
        );
      }
    },
    [onSave],
  );

  const handleOnDateTimeChange = useCallback(
    (v: DateRange<Dayjs>) => {
      // If the end date is before the start date, set the end date to the start date and it should be the start of the day
      if (v[0] && !v[1]?.isValid()) {
        openSnackbar('Please select a valid end date.', SNACKBARTYPE.INFO);
      }
      if (v[1]?.isBefore(v[0], GRANULARITY)) {
        openSnackbar(
          'Invalid date-time range. Please select a valid date range.',
          SNACKBARTYPE.ERROR,
        );
      }
      setValue(() => {
        return [
          v[0] ? v[0]?.startOf(GRANULARITY) : null,
          v[1] ? v[1]?.endOf(GRANULARITY) : null,
        ];
      });
      if (
        v[0]?.isValid() &&
        v[1]?.isValid() &&
        (v[0].isSame(v[1], GRANULARITY) || v[0].isBefore(v[1], GRANULARITY))
      ) {
        onSave?.(
          formatDateTime(v[0].startOf(GRANULARITY)),
          formatDateTime(v[1].endOf(GRANULARITY)),
        );
      }
    },
    [onSave, openSnackbar],
  );
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}>
      {!disablePresets && (
        <AppSelector
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          textSx={{
            backgroundColor: Colors.white,
            fontSize: 12,
            fontWeight: 600,
            height: '30px',
            '& .MuiInputBase-input': {
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              width: 60,
            },
          }}
          MenuProps={{
            MenuListProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  fontSize: '12px !important',
                  fontWeight: 500,
                },
              },
            },
            PaperProps: {
              sx: {
                maxHeight: 310,
              },
            },
          }}
          disabled={disabled}
          value={selectedPreset}
          onChange={handleOnPresetChange}>
          <MenuItem value="custom" disabled>
            Custom
          </MenuItem>
          {rangePresets.map((preset) => (
            <MenuItem key={preset.id} value={preset.id}>
              {preset.label}
            </MenuItem>
          ))}
        </AppSelector>
      )}

      <DateRangePicker
        value={value}
        onChange={handleOnDateTimeChange}
        disabled={disabled}
        format="DD MMM YYYY"
        calendars={1}
        maxDate={dayjs.tz().endOf('day')}
        slots={{
          textField: TextField,
        }}
        slotProps={{
          textField: ({ position }) => ({
            placeholder: position === 'start' ? 'Start Date' : 'End Date',
            label: '',
            InputLabelProps: {
              sx: {
                display: 'none',
              },
            },
            InputProps: {
              readOnly: true,
            },
            'aria-label': 'Date Range',
            sx: {
              width: 100,
              backgroundColor: Colors.white,
              borderRadius: '6px',
              '& fieldset': {
                borderRadius: '6px',
              },
              '& input': {
                padding: '12px 10px',
                fontSize: 12,
                fontWeight: 600,
                height: '6px',
              },
            },
          }),
        }}
        reduceAnimations
      />
    </Box>
  );
};

export default AppDateRangePicker;
