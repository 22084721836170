import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { usePaginationState } from '../../../hooks/usePaginationState';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';
import {
  setReportPaginationSkip,
  setReportPaginationStateLimit,
} from '../redux/reportPaginationSlice';
import {
  fetchShiftSummaryData,
  fetchShiftSummaryReportStatus,
} from '../redux/shiftSummarySlice';
import ShiftsSummaryTableView from './ShiftsSummaryTableView';

const ShiftsSummaryContentView = () => {
  const dispatch = useAppDispatch();

  const { extras } = useAppSelector(selectShiftSummary);
  const { reportStartDate, reportEndDate } = useAppSelector((state) => ({
    reportStartDate: dayjs(selectApp(state).reportStartDate)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    reportEndDate: dayjs(selectApp(state).reportEndDate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
  }));

  const { pageSize, pageIndex, onChange, total } = usePaginationState({
    total: extras.total,
    pageSize: extras.limit ?? 10,
    pageIndex:
      (extras.skip ?? 0) > 0
        ? Math.floor((extras.skip ?? 0) / (extras.limit ?? 0))
        : 0,
  });

  const fetchShiftSummaryDataCallback = useCallback(async () => {
    dispatch(setReportPaginationSkip(pageIndex * pageSize));
    dispatch(setReportPaginationStateLimit(pageSize));

    await dispatch(
      fetchShiftSummaryData({
        date_from: reportStartDate,
        date_to: reportEndDate,
      }),
    );
  }, [dispatch, pageIndex, pageSize, reportStartDate, reportEndDate]);

  const fetchShiftSummaryReportStatusCallback = useCallback(async () => {
    await dispatch(fetchShiftSummaryReportStatus());
  }, [dispatch]);

  useEffect(() => {
    fetchShiftSummaryDataCallback();
  }, [fetchShiftSummaryDataCallback]);

  useEffect(() => {
    fetchShiftSummaryReportStatusCallback();
  }, [fetchShiftSummaryReportStatusCallback]);

  return (
    <ShiftsSummaryTableView
      total={total}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onChange={onChange}
    />
  );
};

export default ShiftsSummaryContentView;
