import ReportsLayout from '../../../hocs/ReportsLayout';
import ShiftsSummaryContentView from '../views/ShiftsSummaryContentView';
import ShiftsSummaryFilterView from '../views/ShiftsSummaryFilterView';
import ShiftsSummaryHeaderView from '../views/ShiftsSummaryHeaderView';

const ShiftsSummaryPage = () => {
  return (
    <ReportsLayout
      ContentComponent={ShiftsSummaryContentView}
      FilterComponent={ShiftsSummaryFilterView}
      HeaderComponent={ShiftsSummaryHeaderView}
    />
  );
};

export default ShiftsSummaryPage;
